import React from 'react';
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import Sider from 'antd/es/layout/Sider';
import FilterDrawerMenu from './FilterDrawerMenu';

interface FilterDrawerSiderProps {
    selectedKey: string;
    onClick: MenuClickEventHandler;
}

const FilterDrawerSider: React.FC<FilterDrawerSiderProps> = ({ selectedKey, onClick }) => {
    return (
        <Sider
            width={200}
            theme='light'
            style={{
                overflowY: 'auto',
                maxHeight: '100vh',
            }}
        >
            <FilterDrawerMenu mode='inline' selectedKey={selectedKey} onClick={onClick} />
        </Sider>
    )
};

export default FilterDrawerSider;