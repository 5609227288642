import React, { useState } from 'react';
import { Drawer, Layout, Grid } from 'antd';
import FilterDrawerContent from './FilterDrawerContent';
import { FilterOption, stockFilterOptions } from '../../../features/filter/model';
import FilterDrawerHeader from './FilterDrawerHeader';
import FilterDrawerSider from './FilterDrawerSider';

const { useBreakpoint } = Grid;

interface FilterDrawerProps {
    open: boolean;
    close: () => void;
}

const FilterDrawer: React.FC<FilterDrawerProps> = ({ open, close }) => {
    const screens = useBreakpoint();
    const [selectedOption, setSelectedOption] = useState<FilterOption | undefined>(undefined);

    const handleChipClick = (filterName: string) => {
        const filter = stockFilterOptions.find(option => option.key === filterName);
        setSelectedOption(filter);
    };

    const onClose = () => {
        setSelectedOption(undefined)
        close()
    }

    return (
        <Drawer
            title="필터 추가"
            placement="right"
            width={720}
            onClose={onClose}
            open={open}
            styles={{ body: { padding: 0 } }}
        >
            <Layout style={{ height: '100%' }}>
                {!screens.md && (
                    <FilterDrawerHeader selectedKey={selectedOption?.key ?? ''} onClick={(e) => handleChipClick(e.key)} />
                )}
                <Layout>
                    {screens.md && (
                        <FilterDrawerSider selectedKey={selectedOption?.key ?? ''} onClick={(e) => handleChipClick(e.key)} />
                    )}
                    <FilterDrawerContent selectedOption={selectedOption} />
                </Layout>
            </Layout>
        </Drawer>
    );
};

export default FilterDrawer;