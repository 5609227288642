import { koreanCurrency } from "../../utils";
import { StockHeaderMap } from "../stocks/model";

// 거래소
export interface Exchange {
    key: string;
    name: string;
}

export const exchanges: Exchange[] = [
    { key: "KOSPI_NORMAL", name: "KOSPI" },
    { key: "KOSDAQ_NORMAL", name: "KOSDAQ" },
    { key: "PREFERED", name: "우선주" },
    { key: "ETF", name: "ETF" },
]

// 필터
export interface Range {
    min?: number;
    max?: number;
    periodInMonth?: number;
}

export const rangeToLabel = (range: Range, unit: string) => {
    const min = range.min !== undefined ? `${koreanCurrency(range.min)}${unit} 이상` : undefined
    const max = range.max !== undefined ? `${koreanCurrency(range.max)}${unit} 미만` : undefined

    var label = ''
    if (range.periodInMonth !== undefined) {
        label = `${range.periodInMonth}개월 - `
    }

    if (min !== undefined && max !== undefined) {
        label += `${min} ~ ${max}`
    } else {
        if (min !== undefined) {
            label += min
        } else if (max !== undefined) {
            label += max
        }
    }

    return label;
}

export const filterValueTypes = {
    range: 'range',
    string: 'string'
} as const;
type FilterValueType = typeof filterValueTypes[keyof typeof filterValueTypes];

const filterTypes = {
    marketCap: 'marketCapRange',
    per: 'perRange',
    pbr: 'pbrRange',
    eps: 'epsRange',
    bps: 'bpsRange',
    opmRate: 'opmRateRange',
    revenue: 'revenueRange',
    netProfitRage: 'netProfitRateRange',
    deptRatio: 'deptRatioRange',
    quickRatio: 'quickRatioRange',
    priceChange: 'priceChange',
    lastDividendRate: 'lastDividendRate',
    fiveYearsDividendChangeRate: 'fiveYearsDividendChangeRate',
    dividendGrowthSince: 'dividendGrowthSince'
} as const;

export interface StockFilterItem {
    key: string;
    range?: Range;
    string?: string;
}

export const stockFilterItems: StockFilterItem[] = [
    { key: filterTypes.marketCap },
    { key: filterTypes.per },
    { key: filterTypes.pbr },
    { key: filterTypes.eps },
    { key: filterTypes.bps },
    { key: filterTypes.opmRate },
    { key: filterTypes.revenue },
    { key: filterTypes.netProfitRage },
    { key: filterTypes.deptRatio },
    { key: filterTypes.quickRatio },
    { key: filterTypes.priceChange },
    { key: filterTypes.lastDividendRate },
    { key: filterTypes.fiveYearsDividendChangeRate },
    { key: filterTypes.dividendGrowthSince },
];

// 필터옵션
export interface FilterOption {
    key: string;
    label: string;
    description: string;
    type: FilterValueType;
    unit: string;
    step: number;
    min: number;
    max: number;
    ranges?: Range[];
    periodTypes?: number[];
}

export const stockFilterOptions: FilterOption[] = [
    {
        key: filterTypes.marketCap,
        label: StockHeaderMap["marketCap"],
        description: "전 상장주식을 시가로 평가한 총액",
        type: filterValueTypes.range,
        unit: "원",
        step: 10_000_000_000,
        min: 0,
        max: 1_000_000_000_000_000,
        ranges: [
            { max: 300_000_000_000 },
            { min: 300_000_000_000, max: 1_000_000_000_000 },
            { min: 1_000_000_000_000 },
        ]
    },
    {
        key: filterTypes.per,
        label: StockHeaderMap["per"],
        description: "주가를 주당순이익으로 나눈 것으로, 주가가 주당 순이익의 배율이 얼마인가를 나타내는 지표",
        type: filterValueTypes.range,
        unit: "배",
        step: 1,
        min: 0,
        max: 1_000,
        ranges: [
            { max: 10 },
            { min: 10, max: 20 },
            { min: 20 },
        ]
    },
    {
        key: filterTypes.pbr,
        label: StockHeaderMap["pbr"],
        description: "주가를 주당순자산가치로 나눈 시장가치비율로, 기업의 순자산에 대해 1주당 몇 배 거래되고 있는지 측정",
        type: filterValueTypes.range,
        unit: "배",
        step: 0.1,
        min: 0,
        max: 100,
        ranges: [
            { max: 1 },
            { min: 1, max: 2 },
            { min: 2 },
        ]
    },
    {
        key: filterTypes.eps,
        label: StockHeaderMap["eps"],
        description: "기업의 순이익(당기순이익)을 유통주식수로 나눈 수치",
        type: filterValueTypes.range,
        unit: "원",
        step: 100,
        min: 0,
        max: 100_000,
        ranges: [
            { max: 5_000 },
            { min: 5_000, max: 10_000 },
            { min: 10_000 },
        ]
    },
    {
        key: filterTypes.bps,
        label: StockHeaderMap["bps"],
        description: "회사의 순자산을 회사가 발행한 총 주식수로 나눈 값",
        type: filterValueTypes.range,
        unit: "원",
        step: 500,
        min: 0,
        max: 1_000_000,
        ranges: [
            { max: 50_000 },
            { min: 50_000, max: 100_000 },
            { min: 100_000 },
        ]
    },
    {
        key: filterTypes.opmRate,
        label: StockHeaderMap["opmRate"],
        description: "매출액에 대한 영업이익의 비율",
        type: filterValueTypes.range,
        unit: "%",
        step: 0.1,
        min: 0,
        max: 100,
        ranges: [
            { max: 10 },
            { min: 10, max: 20 },
            { min: 20 },
        ]
    },
    {
        key: filterTypes.revenue,
        label: StockHeaderMap["revenue"],
        description: "기업에서 생산한 제품이나 서비스를 판매 해서 얻은 수익",
        type: filterValueTypes.range,
        unit: "원",
        step: 10_000_000_000,
        min: 0,
        max: 1_000_000_000_000_000,
        ranges: [
            { max: 300_000_000_000 },
            { min: 300_000_000_000, max: 1_000_000_000_000 },
            { min: 1_000_000_000_000 },
        ]
    },
    {
        key: filterTypes.netProfitRage,
        label: StockHeaderMap["netProfitRate"],
        description: "매출액에 대한 순이익의 비율",
        type: filterValueTypes.range,
        unit: "%",
        step: 0.1,
        min: 0,
        max: 100,
        ranges: [
            { max: 5 },
            { min: 5, max: 10 },
            { min: 10 },
        ]
    },
    {
        key: filterTypes.deptRatio,
        label: StockHeaderMap["deptRatio"],
        description: "총자본 대비 총부채의 비율",
        type: filterValueTypes.range,
        unit: "%",
        step: 1,
        min: 0,
        max: 1_000,
        ranges: [
            { max: 100 },
            { min: 100, max: 200 },
            { min: 200 },
        ]
    },
    {
        key: filterTypes.quickRatio,
        label: StockHeaderMap["quickRatio"],
        description: "유동자산에서 재고자산을 제외한 자산인 당좌자산을 유동부채로 나눈 비율",
        type: filterValueTypes.range,
        unit: "%",
        step: 1,
        min: 0,
        max: 1_000,
        ranges: [
            { max: 100 },
            { min: 100, max: 200 },
            { min: 200 },
        ]
    },
    {
        key: filterTypes.priceChange,
        label: StockHeaderMap["priceChange"],
        description: "최근 N 개월 간 주가 수익률",
        type: filterValueTypes.range,
        unit: "%",
        step: 0.1,
        min: 0,
        max: 1_000,
        ranges: [
            { max: 5 },
            { min: 5, max: 10 },
            { min: 10 },
        ],
        periodTypes: [
            1, 3, 6, 12, 60 // 개월
        ]
    },
    {
        key: filterTypes.lastDividendRate,
        label: StockHeaderMap["lastDividendRate"],
        description: "최근 1년간 배당률 범위",
        type: filterValueTypes.range,
        unit: "%",
        step: 0.05,
        min: 0,
        max: 100,
        ranges: [
            { max: 3 },
            { min: 3, max: 5 },
            { min: 5 },
        ]
    },
    {
        key: filterTypes.fiveYearsDividendChangeRate,
        label: StockHeaderMap["fiveYearsDividendChangeRate"],
        description: "5년 평균 배당성장률 범위",
        type: filterValueTypes.range,
        unit: "%",
        step: 0.05,
        min: 0,
        max: 100,
        ranges: [
            { max: 3 },
            { min: 3, max: 5 },
            { min: 5 }
        ]
    },
    {
        key: filterTypes.dividendGrowthSince,
        label: StockHeaderMap["dividendGrowthSince"],
        description: "특정 연도 이후 배당을 꾸준히 성장해서 지불 (2019년 부터)",
        type: filterValueTypes.string,
        unit: "년",
        step: 1,
        min: 2019,
        max: 2999,
    }
]