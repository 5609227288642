import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './assets/styles/App.css';
import FilterPage from './pages/FilterPage';
import StockSimulationPage from "./pages/StockSimulationPage";
import { useDispatch } from 'react-redux';
import { AppDispatch } from './store/store';
import { fetchStocksAsync } from './features/stocks/slice';
import { exchanges } from './features/filter/model';

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const fetchInitialData = () => {
      const param = { 'category': exchanges[0].key };
      dispatch(fetchStocksAsync(param));
    };

    fetchInitialData()
  }, [dispatch])

  return (
    <Router>
      <div className="App" style={{ width: "100%" }}>
        <Routes>
          <Route path="/" element={<FilterPage />} />
          <Route path="/stocks/selected" element={<StockSimulationPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
