import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllStocks, getStocksStatus } from '../../features/stocks/slice';
import { NetworkStatus } from '../../types/NetworkStatus';
import { Empty, Flex, Skeleton, Table } from "antd";
import { Stock } from "../../features/stocks/model";
import { generateColumnsByDatasource } from "../../utils";
import { TableRowSelection } from 'antd/es/table/interface';
import { AppDispatch } from '../../store/store';
import { select } from '../../features/select/slice';

const FilterStockList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    const stocks = useSelector(selectAllStocks);
    const status = useSelector(getStocksStatus);

    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    useEffect(() => {
        setSelectedRowKeys([])
    }, [stocks])

    const onSelectChange = (newSelectedRowKeys: React.Key[], selectedRows: Stock[]) => {
        setSelectedRowKeys(newSelectedRowKeys);
        dispatch(select(selectedRows))
    };

    const rowSelection: TableRowSelection<Stock> = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    let content;
    if (status === NetworkStatus.Succeeded) {
        content = <>
            <Table
                sticky={true}
                rowSelection={rowSelection}
                rowKey={'ticker'}
                dataSource={stocks}
                columns={generateColumnsByDatasource(stocks) as any[]}
                pagination={false}
                bordered={true}
                style={{
                    padding: '0px 16px',
                    overflowY: 'auto',
                    maxHeight: '70vh',
                }}
                scroll={{ x: '500px' }}
            />
        </>;
    } else if (status === NetworkStatus.Loading) {
        content = <>
            <Flex
                gap="small"
                vertical
            >
                {Array.from({ length: 8 }).map((_, index) => (
                    <Skeleton.Input
                        key={index}
                        style={{ width: "100%", height: index === 0 ? '100px' : '50px' }}
                        active={true}
                        size="default"
                    />
                ))}
            </Flex>
        </>;
    } else if (status === NetworkStatus.Failed) {
        content = <>
            <Empty description="데이터를 가져오는데 실패했습니다. 잠시 후 다시 시도해주세요." />
        </>;
    }

    return (
        <section >
            {content}
        </section>
    );
};

export default FilterStockList;