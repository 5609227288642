import React from 'react';
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import FilterDrawerMenu from './FilterDrawerMenu';

interface FilterDrawerHeaderProps {
    selectedKey: string;
    onClick: MenuClickEventHandler;
}

const FilterDrawerHeader: React.FC<FilterDrawerHeaderProps> = ({ selectedKey, onClick }) => {
    return (
        <FilterDrawerMenu mode='horizontal' selectedKey={selectedKey} onClick={onClick} />
    )
};

export default FilterDrawerHeader;