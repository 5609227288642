import React, { useEffect } from 'react';
import { Flex } from 'antd';
import FilterHeader from '../components/filter/FilterHeader';
import FilterStockList from '../components/filter/FilterStockList';
import SelectSection from '../components/filter/sections/SelectSection';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store/store';
import { resetSelection } from '../features/select/slice';

const FilterPage: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(resetSelection());
      }, [dispatch]);

    return (
        <Flex
            gap="middle"
            vertical
            style={{ padding: '16px' }}
        >
            <FilterHeader />
            <FilterStockList />
            <SelectSection />
        </Flex>
    );
};

export default FilterPage;
