import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from "antd";
import { Stock } from "../../features/stocks/model";
import { generateColumnsByDatasource, round, stockTableHeaderFormatter } from "../../utils";
import { AppDispatch } from '../../store/store';
import { getSelectionStocks, getSimulationParam, getSimulationRatio, getWeight, setSimulationParam, setSimulationRatio } from '../../features/select/slice';
import { SimulationParam, Weight, WeightKey } from '../../features/select/model';

const customColumns = [
    {
        title: '비율',
        key: 'ratio',
        render: (stock: any) => (<div>{`${round(stock.ratio as number * 100)}%`}</div>),
        width: '10vw',
        fixed: 'right',
        sorter: (a: any, b: any) => a.ratio! > b.ratio! ? 1 : -1,
        defaultSortOrder: 'descend',
    },
    {
        title: '투자비용',
        key: 'amount',
        render: (stock: any) => <div>{stockTableHeaderFormatter('marketCap')!(stock.amount as number)}</div>,
        width: '20vw',
        order: 'right',
        fixed: 'right',
        sorter: (a: any, b: any) => a.amount! > b.amount! ? 1 : -1,
    },
]

const SimulationStockList: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();

    const weight = useSelector(getWeight);
    const stocks = useSelector(getSelectionStocks);
    const param = useSelector(getSimulationParam);
    const ratios = useSelector(getSimulationRatio);
    
    const columns: any[] = [...generateColumnsByDatasource(stocks), ...customColumns]
    const { initialMoney } = param;

    useEffect(() => {
        const calculateRatioByStandard = (weight: Weight, stocks: Stock[], params: SimulationParam): any[] => {
            if (stocks.length === 0) return [];
    
            let dividendRate = 0;
            let dividendGrowthRate = 0;
            let result: any[] = []
    
            if (weight.key === WeightKey.MarketCap) {
                const sum = stocks.reduce((sum, current) => sum + (current.marketCap ?? 0), 0);
                result = stocks.map(
                    (stock) => {
                        if ((stock.marketCap ?? 0) === 0) return { ...stock, ratio: 0, amount: 0 }
                        const ratio = round(stock.marketCap! / sum * 100) / 100
                        const amount = ratio * params.initialMoney - ratio * params.initialMoney % 100
                        dividendRate += (stock.lastDividendRate ?? 0) * ratio
                        dividendGrowthRate += (stock.fiveYearsDividendChangeRate ?? 0) * ratio
                        return { ...stock, ratio, amount }
                    }
                )
            } else if (weight.key === WeightKey.Equal) {
                const count = stocks.length
                const ratio = round(1 / count * 100) / 100
                result = stocks.map((stock) => {
                    const amount = ratio * params.initialMoney - ratio * params.initialMoney % 100
                    dividendRate += (stock.lastDividendRate ?? 0) * ratio
                    dividendGrowthRate += (stock.fiveYearsDividendChangeRate ?? 0) * ratio
                    return { ...stock, ratio, amount }
                })
            }
    
            dispatch(setSimulationParam({
                ...params, 'dividendRate': dividendRate, 'dividendGrowthRate': dividendGrowthRate,
            }))
    
            return result
        }
        
        const ratios = calculateRatioByStandard(weight, stocks, param)
        dispatch(setSimulationRatio(ratios))
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [weight, stocks, initialMoney]);

    return (
        <Table
            sticky={true}
            dataSource={ratios}
            columns={columns}
            pagination={false}
            bordered={true}
            style={{ width: '80%' }}
            scroll={{ x: '500px', y: '600px' }}
        />
    );
};

export default SimulationStockList;