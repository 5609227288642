import React from 'react';
import { Menu } from 'antd';
import { MenuClickEventHandler, MenuMode } from 'rc-menu/lib/interface'
import { stockFilterOptions } from '../../../features/filter/model';

interface FilterDrawerMenuProps {
    mode: MenuMode;
    selectedKey: string;
    onClick: MenuClickEventHandler;
}

const FilterDrawerMenu: React.FC<FilterDrawerMenuProps> = ({ mode, selectedKey, onClick }) => {
    return (
        <Menu
            mode={mode}
            selectedKeys={[selectedKey]}
            onClick={onClick}
        >
            {stockFilterOptions.map((option) => (
                <Menu.Item key={option.key}>{option.label}</Menu.Item>
            ))}
        </Menu>
    )
};

export default FilterDrawerMenu;